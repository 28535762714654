import React, { useState } from "react";
import "./AdminOrders.scss";
import toggleImg from "../../img/passportToggle.svg";
import Spinner from "../Spinner/Spinner";
import emptyImg from "../../img/transactionEmptyImg.svg";
import CopyNotification from "../CopyNotification/CopyNotification";
import OrderService from "../../services/OrderService";
import TruncatedText from "../TruncedText/TruncedText";

function AdminOrders() {
	const [orders, setOrders] = useState([]);
	const [isClosed, setIsClosed] = useState(false);
	const [notification, setNotification] = useState({
		visible: false,
		message: "",
	});
	const [confirmDialog, setConfirmDialog] = useState({
		visible: false,
		orderId: null,
	});
	const [loading, setLoading] = useState(false);
	const [searchParams, setSearchParams] = useState({
		startDate: "",
		endDate: "",
		userId: "",
		orderId: "",
	});

	const formatDate = (utcDateString) => {
		const date = new Date(utcDateString);
		const options = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
		};
		return date.toLocaleString(undefined, options);
	};

	const copyToClipboard = (text) => {
		navigator.clipboard
			.writeText(text)
			.then(() =>
				setNotification({ visible: true, message: "Скопировано в буфер обмена" })
			)
			.catch((err) => console.error("Ошибка копирования: ", err));
	};

	const handleNotificationClose = () => setNotification({ visible: false, message: "" });

	const handleDateChange = (e) => {
		const { name, value } = e.target;
		const digitsOnly = value.replace(/\D/g, "");
		let formattedValue = digitsOnly;

		if (digitsOnly.length > 2) {
			formattedValue = `${digitsOnly.slice(0, 2)}.${digitsOnly.slice(2, 4)}`;
		}
		if (digitsOnly.length > 4) {
			formattedValue = `${digitsOnly.slice(0, 2)}.${digitsOnly.slice(2, 4)}.${digitsOnly.slice(4, 8)}`;
		}

		setSearchParams((prev) => ({ ...prev, [name]: formattedValue }));
	};

	const handleFilterChange = (e) => {
		const { name, value } = e.target;
		setSearchParams((prev) => ({ ...prev, [name]: value }));
	};

	const applyFilters = async () => {
		setLoading(true);
		try {
			const response = await OrderService.getOrders(
				isClosed ? "completed" : "paid",
				searchParams.startDate,
				searchParams.endDate,
				searchParams.userId,
				searchParams.orderId
			);
			setOrders(response.data.reverse());
		} catch (error) {
			console.error("Ошибка загрузки данных:", error);
		} finally {
			setLoading(false);
		}
	};

	const handleShowConfirmDialog = (orderId) => {
		setConfirmDialog({ visible: true, orderId });
	};

	const handleCloseConfirmDialog = () => {
		setConfirmDialog({ visible: false, orderId: null });
	};

	const handleConfirmMarkAsPaid = async () => {
		const { orderId } = confirmDialog;
		try {
			await OrderService.changeOrderStatus(orderId)
			setOrders((prevOrders) => prevOrders.filter((order) => order.orderId !== orderId));
			setNotification({ visible: true, message: "Заказ успешно отмечен как оплачен!" });
		} catch (error) {
			setNotification({ visible: true, message: "Не удалось обновить статус заказа!" });
		} finally {
			setConfirmDialog({ visible: false, orderId: null });
		}

	};

	return (
		<div className="transactions">
			<h1>{isClosed ? "Выполненные заказы" : "Новые заказы"}</h1>
			<button className="toggle_button" onClick={() => setIsClosed(!isClosed)}>
				<img src={toggleImg} alt="img" />
			</button>
			<div className="filters">
				<div className="__input_wrapper">
					<label>Дата начала:</label>
					<input
						type="text"
						name="startDate"
						value={searchParams.startDate}
						onChange={handleDateChange}
						placeholder="ДД.ММ.ГГГГ"
					/>
				</div>
				<div className="__input_wrapper">
					<label>Дата конца:</label>
					<input
						type="text"
						name="endDate"
						value={searchParams.endDate}
						onChange={handleDateChange}
						placeholder="ДД.ММ.ГГГГ"
					/>
				</div>
				<div className="__input_wrapper">
					<label>User ID:</label>
					<input
						type="text"
						name="userId"
						value={searchParams.userId}
						onChange={handleFilterChange}
					/>
				</div>
				<div className="__input_wrapper">
					<label>Order ID:</label>
					<input
						type="text"
						name="orderId"
						value={searchParams.orderId}
						onChange={handleFilterChange}
					/>
				</div>
				<button className="apply-button" onClick={applyFilters}>
					Применить
				</button>
			</div>

			{loading ? (
				<Spinner />
			) : orders.length > 0 ? (
				<div className="wrapper">
					<div className="table-wrapper">
						<table className="transaction-table">
							<thead>
							<tr>
								<th>ID транзакции</th>
								<th>ID пользователя</th>
								<th>Отправлено</th>
								<th>Получено</th>
								<th>Курс</th>
								<th>Кошелек</th>
								<th>Сеть</th>
								<th>Memo</th>
								<th>Заметка</th>
								<th>Дата</th>
								<th>Статус</th>
							</tr>
							</thead>
							<tbody>
							{orders.map((order, i) => (
								<tr key={i}>
									<td>
										<TruncatedText text={order.orderId}/>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
										     fill="none" cursor="pointer" className="__copy"
										     onClick={() => copyToClipboard(order.orderId)}>
											<path fillRule="evenodd" clipRule="evenodd"
											      d="M9 3h11v13h-3V6H9V3zM4 8v13h11V8.02L4 8z"
											      fill="currentColor"></path>
										</svg>
									</td>
									<td>{order.userId}</td>
									<td>{order.sumIn}&nbsp;₽</td>
									<td>{order.sumOut}&nbsp;USDT</td>
									<td>{order.rate}</td>
									<td>
										<TruncatedText text={order.wallet}/>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
										     fill="none" cursor="pointer" className="__copy"
										     onClick={() => copyToClipboard(order.wallet)}>
											<path fillRule="evenodd" clipRule="evenodd"
											      d="M9 3h11v13h-3V6H9V3zM4 8v13h11V8.02L4 8z"
											      fill="currentColor"></path>
										</svg>
									</td>
									<td>{order.network}</td>
									<td>{order.userMemo || "-"}</td>
									<td>{order.note || "-"}</td>
									<td>{formatDate(order.time)}</td>
									<td>
										{order.status === "completed" ? (
											<button
												className="absolute-mark-paid-button"
												onClick={() => handleShowConfirmDialog(order.orderId)}
											>
												Завершить
											</button>
											) : (
											"Завершено"
											)}
									</td>
								</tr>
							))}
							</tbody>
						</table>
					</div>
				</div>
			) : (
				<div className="__empty">
					<div className="__image">
						<img src={emptyImg} alt="No Transactions"/>
					</div>
					<h2>На данный момент нет обменов или транзакций</h2>
				</div>
			)}

			{confirmDialog.visible && (
				<div className="confirm-dialog">
					<div className="dialog-content">
						<p>Вы уверены, что хотите отметить заказ как оплаченный?</p>
						<button className="cancel-button" onClick={handleCloseConfirmDialog}>
							Отмена
						</button>
						<button className="confirm-button" onClick={handleConfirmMarkAsPaid}>
							Подтвердить
						</button>
					</div>
				</div>
			)}
			<CopyNotification
				message={notification.message}
				visible={notification.visible}
				onClose={handleNotificationClose}
			/>
		</div>
	);
}

export default AdminOrders;
