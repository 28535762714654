import React, {useState, useEffect, useContext} from 'react';
import "./Exchanger.scss";
import tether from "../../img/tether.svg";
import rub from "../../img/rubIcon.svg";
import swap from "../../img/swapImg.svg";
import infoImg from "../../img/exchangeInfo.svg";
import ExchangeService from "../../services/ExchangeService";
import {useNavigate} from "react-router-dom";
import {Context} from "../../index";

function Exchanger() {
	const {store} = useContext(Context);
	const [selectedFirstInput, setSelectedFirstInput] = useState({
		transcript: "RUB",
		img: rub,
	});
	const [selectedSecondInput, setSelectedSecondInput] = useState({
		transcript: "USDT",
		img: tether,
	});
	const [firstInput, setFirstInput] = useState("");
	const [secondInput, setSecondInput] = useState("");
	const [isValidFirstInput, setIsValidFirstInput] = useState(false);
	const [isValidSecondInput, setIsValidSecondInput] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [rate, setRate] = useState("");
	const [status, setStatus] = useState("");
	const isButtonDisabled = status === "manual" || status === "time";

	const navigate = useNavigate();


	useEffect(() => {
		const fetchExchangeRate = async () => {
			try {
				const response = await ExchangeService.getExchangeRate(store.user.id);
				setRate(response.data.rate);
				setStatus(response.data.message);
			} catch (error) {
				console.error('Error fetching exchange rate:', error);
			}
		};

		fetchExchangeRate();
		const interval = setInterval(() => {
			fetchExchangeRate();
		}, 300000);

		return () => clearInterval(interval);
	}, []);

	const handleSwap = () => {
		// const tempFirstInput = { ...selectedFirstInput };
		// setSelectedFirstInput({ ...selectedSecondInput });
		// setSelectedSecondInput(tempFirstInput);
		// setFirstInput("");
		// setSecondInput("");
		// setErrorMessage("");
	}

	const handleInputChange = (event, isFirstInput) => {
		let inputValue = event.target.value;

		const roundToTwoDecimals = (num) => (Math.round(num * 100) / 100).toString();
		const roundToThreeDecimals = (num) => (Math.round(num * 1000) / 1000).toString();

		if(isFirstInput){
			setFirstInput(inputValue);
			setSecondInput(roundToThreeDecimals(parseFloat(inputValue) / parseFloat(rate)));
		}
		else{
			setSecondInput(inputValue);
			setFirstInput(roundToTwoDecimals(parseFloat(inputValue) * parseFloat(rate)));
		}
	}

	const handleSubmit = () => {
		if (!firstInput || !secondInput) {
			setIsValidFirstInput(false);
			setIsValidSecondInput(false);
			setErrorMessage("Введите сумму для обмена!");
			return;
		}

		const inputAmount = parseFloat(firstInput);

		if(inputAmount < 1200){
			setErrorMessage("Минимальная сумма обмена 1200 RUB!");
			return;
		}

		if (store.user.isActivated && !store.user.isVerified && inputAmount > 14990) {
			setErrorMessage("Ваш лимит 14,990 RUB. Пройдите верификацию, чтобы увеличить лимит!");
			return;
		}

		if (store.user.isActivated && store.user.isVerified && inputAmount > 500000) {
			setErrorMessage("Слишком большая сумма. Максимум 500,000 RUB!");
			return;
		}

		setIsValidFirstInput(false);
		setIsValidSecondInput(false);

		const receivedNum = parseFloat(firstInput);
		const giveNum = parseFloat(secondInput);
		const exchangeRate = parseFloat(rate);

		const recalculatedReceivedNum = Math.round(giveNum * exchangeRate * 100) / 100;

		const difference = recalculatedReceivedNum - receivedNum;

		const adjustedReceivedNum =
			Math.abs(difference) > 0.01 ? (receivedNum + difference).toFixed(2) : receivedNum.toFixed(2);

		const data = {
			receivedNum: adjustedReceivedNum,
			receivedCurrency: selectedFirstInput.transcript,
			giveNum: giveNum.toFixed(3),
			giveCurrency: selectedSecondInput.transcript,
			rate: rate,
		};

		navigate('/details', { state: { data } });
	};

	return (
		<div className="exchanger">
			<h2>
				Купить {selectedFirstInput.transcript === "RUB" ? "USDT" : "RUB"}
				<div className="info-icon">
					<span className="tooltip-text">Время работы<br/> 08:00 – 20:00 (UTC+3)</span>
					<img src={infoImg} alt="img"/>
				</div>
			</h2>
			<div className="__input_wrapper">
				<div className="__item">
					<div className={isValidFirstInput ||
									store.user.isActivated && !store.user.isVerified && parseFloat(firstInput) > 14990 ||
									store.user.isActivated && store.user.isVerified && parseFloat(firstInput) > 500000
						? "__content_wrapper invalid" : "__content_wrapper"}>
						<div className="__input">
							<div className="__text">
								<p>Вы отдадите: </p>
							</div>
							<input type="text"
							       value={/^[0-9.]*$/.test(firstInput) ? firstInput : ""}
							       onChange={(event) => handleInputChange(event, true)}
							       onFocus={(event) => event.target.select()}
							       placeholder={store.user.isVerified
								       ? "1200.00 - 200 000.00"
								       : store.user.isActivated
									       ? "1200.00 - 14 990.00"
									       : "0.00"}
							/>
						</div>
						<div className="__currency">
							<img src={selectedFirstInput.img} alt="img"/>
							<span>{selectedFirstInput.transcript}</span>
						</div>
					</div>
				</div>
				<div className="__swap">
					<img onClick={handleSwap} src={swap} alt="img"/>
				</div>
				<div className="__item">
					<div className={isValidSecondInput ? "__content_wrapper invalid" : "__content_wrapper"}>
						<div className="__input">
							<div className="__text">
								<p>Вы получите: </p>
							</div>
							<input type="text"
							       value={/^[0-9.]*$/.test(secondInput) ? secondInput : ""}
							       onChange={(event) => handleInputChange(event, false)}
							       onFocus={(event) => event.target.select()}
							       placeholder="0.00"
							/>
						</div>
						<div className="__currency">
							<img src={selectedSecondInput.img} alt="img"/>
							<span>{selectedSecondInput.transcript}</span>
						</div>
					</div>
				</div>
			</div>
			<div className="__purchase">
				<div className="__rate">
					<p>1 USDT = {rate} RUB</p>
				</div>
				<button
					onClick={handleSubmit}
					disabled={isButtonDisabled}
					className={isButtonDisabled ? "disabled-button" : ""}
				>
					{isButtonDisabled ? "Отключено" : "Купить"}
				</button>
				{errorMessage && <p className="error-message">{errorMessage}</p>}
			</div>
		</div>
);
}

export default Exchanger;