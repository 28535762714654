import React, {useContext, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import "./ExchangeDetails.scss";
import CustomSelect from "../CustomSelect/CustomSelect";
import PaymentService from "../../services/PaymentService";
import {Context} from "../../index";

function ExchangeDetails() {
	const {store} = useContext(Context);
	const navigate = useNavigate();
	const [wallet, setWallet] = useState('');
	const [network, setNetwork] = useState({
		value: '',
		price: '0'
	});
	const [networkRubPrice, setNetworkRubPrice] = useState(0);
	const [memo, setMemo] = useState('');
	const [note, setNote] = useState('');
	const [errors, setErrors] = useState({});

	const currentLocation = useLocation();
	const { data } = currentLocation.state || {};

	useEffect(() => {
		if (!store.user.id) {
			navigate("/login");
		}
	}, [store.user.id]);

	useEffect(() => {
		if (data && network.price) {
			setNetworkRubPrice(parseFloat(network.price) * parseFloat(data.rate));
		}
	}, [network, data]);

	const networkOptions = [
		{value: 'TRC20,', label: 'Tron(TRC20)', price: "1.5"},
		{value: 'BEP20', label: 'Binance Smart Chain(BEP20)', price: "1"},
		{value: 'TON', label: 'TON', price: "0.3"},
		{value: 'SOL', label: 'Solana', price: "1"}
	]

	const countTotal = () => {
		if (!data) return '0.00';
		return Math.floor((parseFloat(data.receivedNum) + parseFloat(networkRubPrice)) * 100) / 100;
	};

	const validateFields = () => {
		let newErrors = {};
		setErrors({});
		if (!wallet) {
			newErrors.wallet = 'Пожалуйста, введите адрес получателя';
		} else if (/[а-яА-ЯёЁ]/.test(wallet)) {
			newErrors.wallet = 'Пожалуйста, введите актуальный адрес получателя';
		}
		if (!network) newErrors.network = 'Пожалуйста, выберите сеть отправки';
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleWithdraw = async () => {
		if(!store.user){
			navigate("/")
			window.scrollTo(0, 0);
		}
		if (!validateFields()) {
			return;
		}
		const paymentData = {
			...data,
			wallet: wallet,
			network: {
				value: network.value,
				price: networkRubPrice.toFixed(2)
			},
			userMemo: memo ? memo : "",
			note: note ? note : "",
			email: store.user.email,
			userId: store.user.id,
			total: Math.floor((parseFloat(data.receivedNum) + parseFloat(networkRubPrice)) * 100) / 100
		}
		try{
			const response = await PaymentService.createPayment(paymentData);
			if (response && response.data) {
				if (response.data.data.payload) {
					window.open(response.data.data.payload, "_blank");
					navigate("/profile");
					window.scrollTo(0, 0);
				} else {
					console.error("Payload URL is not available");
				}
			}
		}catch (e){
			console.log(e)
		}
	};

	return (
		<div className="__exchange_details">
			<div className="container-top">
				{data ? (
					<div className="__details_wrapper">
						<div className="__details_form">
							<div className="__details">
								<h3>Детали заказа:</h3>
								<div className="__info">
									<span>Вы отправите:</span>
									<strong>{data.receivedNum} {data.receivedCurrency}</strong>
								</div>
								<div className="__info">
									<span>Вы получите:</span>
									<strong>{data.giveNum} {data.giveCurrency}</strong>
								</div>
								<div className="__info">
									<span>Комиссия сети:</span>
									<strong>{network ? network.price : "0"} USDT</strong>
								</div>
								<div className="__info">
									<span>Курс:</span>
									<strong>{data.rate}</strong>
								</div>
							</div>
							<div className="__wallet">
								<h3>Адрес вывода:</h3>
								<CustomSelect
									options={networkOptions}
									value={network}
									onChange={setNetwork}
									error={errors.network}
								/>
								<div className="__input_wrapper">
									<label>Адрес:</label>
									<input
										className={`input ${errors.fullName ? 'input-error' : ''}`}
										type="text"
										value={wallet}
										onChange={(e) => setWallet(e.target.value)}
									/>
									{errors.wallet && <p className="error">{errors.wallet}</p>}
								</div>
								<div className="__input_wrapper">
									<label>Memo (если требуется):</label>
									<input
										className='input'
										type="text"
										value={memo}
										onChange={(e) => setMemo(e.target.value)}
									/>
								</div>
								<div className="__input_wrapper">
									<label>Комментарий (по желанию):</label>
									<input
										className='input'
										type="text"
										value={note}
										onChange={(e) => setNote(e.target.value)}
									/>
								</div>
							</div>
							<div className="__total">
								<h3>Итого к оплате:</h3>
								<div className="__info">
									<span>Сумма RUB + Комиссия:</span>
									<strong>{countTotal()} {data.receivedCurrency}</strong>
								</div>
								<button onClick={handleWithdraw} className="__details_btn">
									Оплатить
								</button>
							</div>
						</div>
					</div>
				) : (
					<div>Нет доступных данных</div>
				)}
			</div>
		</div>
	);
}

export default ExchangeDetails;
